import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// Pages
import Home from "../pages/home/Home";
import Why from "../pages/why/Why";
import Contact from "../pages/contact/Contact";
import Blog from "../pages/blog/Blog";
import HireTalent from "../pages/hireTalent/HireTalent";

function Routes() {
	return (
		<Router>
			<Switch>
				<Route exact path="/">
					<Home />
				</Route>

				<Route path="/why">
					<Why />
				</Route>

				<Route path="/contact">
					<Contact />
				</Route>

				<Route path="/blog">
					<Blog />
				</Route>
				<Route path="/talent">
					<HireTalent />
				</Route>
			</Switch>
		</Router>
	);
}

export default Routes;
