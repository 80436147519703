import React from "react";
import Styled from "styled-components";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";

const HireTalent = () => {
    return (
        <>
            <Header isWhite={true} />
            <TalentTestStyle className="space">
                <div className="text-content">
                    <h3>Connect With Startar’s Talent Pool</h3>
                    <p>Get firsthand access to the very best university/college 
                    students who have established themselves as forces in the 
                    global tech scene.</p>
                    <a href="http://bit.ly/join-startar">Start Hiring</a>
                </div>
            </TalentTestStyle>

            <img className="img-fluid" src="https://res.cloudinary.com/startar/image/upload/v1617429502/Startar/pages/HireTalent/image1_n6y8vp.jpg" alt="first sight"/>

            <BenefitStyle>
                <div className="container">
                    <div className="row">
                        <div className="col-md-5">
                            <h5>Benefits Of Recruiting With Startar.</h5>
                        </div>
                        <div className="col-md-7">
                            <h6>We have gone through the hardwork of onboarding the best of the best, 
                                therefore making it easy for you to employ the very best.</h6>
                            
                            <div className="row">
                                <div className="col-md-6">
                                    <h4>Never Ending Talent Pool</h4>
                                    <p>Joining startar as an employer means you will always have a pool 
                                        of world-class talents to hire from.</p>
                                </div>
                                <div className="col-md-6">
                                    <h4>Updates On The Next Big Talent</h4>
                                    <p>You will get notified whenever we onboard a talent we believe perfectly
                                        suits your company’s profile and culture.</p>
                                </div>
                                <div className="col-md-6">
                                    <h4>Opportunity To Shape A Talent</h4>
                                    <p>We are trusting you with young professionals, and offering you the 
                                        opportunity to be a huge part of someone’s story and growth.</p>
                                </div>
                                <div className="col-md-6">
                                    <h4>Global Recognition</h4>
                                    <p>A global recognition and a reputation that aligns with your reputation of 
                                        caring for the development of your employees.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </BenefitStyle>

            <img className="img-fluid" src="https://res.cloudinary.com/startar/image/upload/v1617429502/Startar/pages/HireTalent/image1_n6y8vp.jpg" alt="second sight"/>

            <TalentTestStyle>
                <div className="text-content">
                    <h3>Join Us Today To Shape Young Professionals.</h3>
                    <p>Since we launched, over 100 reputable global brands have joined this movement</p>
                    <div className="icons-container">
                        <div className="icons"><img src="https://res.cloudinary.com/startar/image/upload/v1617429501/Startar/pages/HireTalent/google_gr43vf.png" alt="google logo"/></div>
                        <div className="icons"><img src="https://res.cloudinary.com/startar/image/upload/v1617429501/Startar/pages/HireTalent/apple_macs0n.png" alt="google logo"/></div>
                        <div className="icons"><img src="https://res.cloudinary.com/startar/image/upload/v1617429501/Startar/pages/HireTalent/microsoft_xv354x.png" alt="google logo"/></div>
                        <div className="icons" />
                        <div className="icons" />
                        <div className="icons" />
                        <div className="icons" />
                        <div className="icons" />
                    </div>
                    <a href="http://bit.ly/join-startar">Start Hiring</a>
                </div>
            </TalentTestStyle>

            <Footer />
        </>
    )
}

export default HireTalent;

const TalentTestStyle = Styled.section`
    width: 100%;
    height: 100%;
    margin-top: 7rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .text-content {
        display: flex;
        flex-direction: column;
        width: 100%;

        @media (max-width: 768px) {
            width: 85%;
            margin: 0 auto;
        }

        h3 {
            font-size: 2rem;
            font-weight: 600;
            font-family: 'Recoleta Alt';
            text-align: center;
            color: #0A1F4D;
            line-height: 44px;
            max-width: 586px;
            margin: 0 auto;
        }

        p {
            font-size: 1.3rem;
            color: #2E4379;
            font-family: 'GT Walsheim Pro', sans-serif;
            font-weight: 300;
            line-height: 32px;
            text-align: center;
            max-width: 586px;
            margin: 0 auto;
        }

        a {
            background: #357DEC;
          padding: 0.75rem 2.5rem;
          border-radius: 10px;
          color: #fff;
          font-family: 'GT Walsheim Pro', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 1rem;
          text-decoration: none;
          position: relative;
          z-index: 1;
          max-width: 180px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto;
          margin-top: 54px;
          margin-bottom: 80px;

          &:hover {
            background: #1a6be8;
            border-radius: 10px;

             &:after {
              transform: scaleX(1);
              transform-origin: left;
              }
          }

           &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,0.2);
            border-radius: 10px;
            z-index: -1;
            transition: transform 200ms ease-in;
            transform: scaleX(0);
            transform-origin: right;
          }
        }
    }

    .icons-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 800px;
        margin: 0 auto;
        margin-top: 60px;

        .icons {
            width: 70px;
            height: 70px;
            border-radius: 50%;
            margin-left: 20px;
            margin-right: 20px;
            background: #c4c4c4;

            &:first-child{
                margin-left: 0;
            }

            &:last-child{
                margin-right: 0;
            }

            @media (max-width: 768px) {
                width: 40px;
                height: 40px;
                margin-left: 10px;
                margin-right: 10px;
            }

            img {
                width: 100%;
                height: auto;
            }
        }
    }

    &.space {
        margin-top: 9rem;
    }
`;

const BenefitStyle = Styled.section`
    width: 100%;
    height: 100%;
    margin: 80px 0;

    h5 {
        font-family: 'Recoleta Alt';
        font-weight: 600;
        font-size: 2rem;
        color: #0A1F4D;
        max-width: 328px;
        line-height: 44px;
    }

    h6 {
        font-size: 1.3rem;
        color: #2E4379;
        font-family: 'GT Walsheim Pro', sans-serif;
        font-weight: 300;
        line-height: 32px;
        max-width: 560px;
        margin-bottom: 3rem;
    }

    h4 {
        font-family: 'Recoleta Alt';
        font-weight: 600;
        font-size: 1.125rem;
        color: #0A1F4D;
        max-width: 328px;
        line-height: 1.5rem;
    }

    p {
        font-size: .9rem;
        color: #2E4379;
        font-family: 'GT Walsheim Pro', sans-serif;
        font-weight: 300;
        line-height: 24px;
        max-width: 300px;
    }
`;
