import React from "react";
import Styled from "styled-components";

import { Link } from "react-router-dom";
import { IoMdArrowForward } from "react-icons/io";

const Stack = () => {
	return (
		<StackStyle>
			<div className="title">
				<h1>Leverage Our Pool Of Worldclass & Untapped Talents</h1>
				<p data-aos-delay="500">
					We have a pool of the very finest students who have established
					themselves as forces in the global tech scene.{" "}
				</p>
			</div>
			<div className="container mt-5">
				<div className="row">
					<div className="col-md-4 mb-4">
						<CardStyle>
							<div className="img-container">
								<img src='https://res.cloudinary.com/startar/image/upload/v1616730268/Startar/pages/home/StackSection/web_iwpxbx.svg' alt="stack" />
							</div>
							<h4>Front-end Engineers</h4>
							<p>
								Our worldclass web developers are vast and very experienced in
								various web development technologies that will cater for any of
								your needs
							</p>
							<Link href="/">
								View Front-end Engineers <IoMdArrowForward />
							</Link>
						</CardStyle>
					</div>
					<div className="col-md-4 mb-4">
						<CardStyle>
							<div className="img-container">
								<img src='https://res.cloudinary.com/startar/image/upload/v1616730268/Startar/pages/home/StackSection/web_iwpxbx.svg' alt="stack" />
							</div>
							<h4>Back-end Engineers</h4>
							<p>
								Our worldclass web developers are vast and very experienced in
								various web development technologies that will cater for any of
								your needs
							</p>
							<Link href="/">
								View Back-end Engineerss <IoMdArrowForward />
							</Link>
						</CardStyle>
					</div>
					<div className="col-md-4 mb-4">
						<CardStyle>
							<div className="img-container">
								<img src='https://res.cloudinary.com/startar/image/upload/v1616730268/Startar/pages/home/StackSection/web_iwpxbx.svg' alt="stack" />
							</div>
							<h4>Mobile Engineers</h4>
							<p>
								Our worldclass web developers are vast and very experienced in
								various web development technologies that will cater for any of
								your needs
							</p>
							<Link href="/">
								View Mobile Engineers <IoMdArrowForward />
							</Link>
						</CardStyle>
					</div>
					<div className="col-md-4 mb-4">
						<CardStyle>
							<div className="img-container">
								<img src='https://res.cloudinary.com/startar/image/upload/v1616730268/Startar/pages/home/StackSection/web_iwpxbx.svg' alt="stack" />
							</div>
							<h4>UX/UI Designers</h4>
							<p>
								Our worldclass web developers are vast and very experienced in
								various web development technologies that will cater for any of
								your needs
							</p>
							<Link href="/">
								View UX/UI Designers <IoMdArrowForward />
							</Link>
						</CardStyle>
					</div>
					<div className="col-md-4 mb-4">
						<CardStyle>
							<div className="img-container">
								<img src='https://res.cloudinary.com/startar/image/upload/v1616730268/Startar/pages/home/StackSection/web_iwpxbx.svg' alt="stack" />
							</div>
							<h4>ML/AI Experts</h4>
							<p>
								Our worldclass web developers are vast and very experienced in
								various web development technologies that will cater for any of
								your needs
							</p>
							<Link href="/">
								View ML/AI Experts <IoMdArrowForward />
							</Link>
						</CardStyle>
					</div>
					<div className="col-md-4 mb-4">
						<CardStyle>
							<div className="img-container">
								<img src='https://res.cloudinary.com/startar/image/upload/v1616730268/Startar/pages/home/StackSection/web_iwpxbx.svg' alt="stack" />
							</div>
							<h4>Product Managers</h4>
							<p>
								Our worldclass web developers are vast and very experienced in
								various web development technologies that will cater for any of
								your needs
							</p>
							<Link href="/">
								View Product Managers <IoMdArrowForward />
							</Link>
						</CardStyle>
					</div>
				</div>
			</div>
		</StackStyle>
	);
};

export default Stack;

const StackStyle = Styled.div`
    width: 100%;
    min-height: 400px;
    background: #F7FAFF;
    padding: 80px 0;

    .title {
        width: 90%;
        max-width: 500px;
        margin: 0 auto;

        h1 {
            font-family: 'Recoleta Alt';
            font-style: normal;
            font-weight: 500;
            font-size: 2rem;
            line-height: 44px;
            text-align: center;
            color: #0A1F4D;
        }

        p {
            font-family: 'GT Walsheim Pro', sans-serif;
            font-style: normal;
            font-weight: 300;
            font-size: 1.5rem;
            line-height: 32px;
            text-align: center;
            color: #2E4379;
            padding-top: 16px;
        }
    }
`;

const CardStyle = Styled.div`
    width: 100%;
    height: 300px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 40px;
    border-radius: 5px;
    transition: all ease-in .3s;

    .img-container {
        background: #E8F0FD;
        width: 64px;
        height: 64px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 16px;
    }

    h4 {
        font-family: 'Recoleta Alt';
        font-style: normal;
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 32px;
        color: #0A1F4D;
    }

    p {
        font-family: 'GT Walsheim Pro', sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 1rem;
        line-height: 24px;
        text-align: center;
        color: #2E4379;
    }

    a {
        display: none;
    }

    &:hover {
      background: #FFFFFF;
      box-shadow: 0px 30px 60px rgba(32, 32, 32, 0.05);
      border-radius: 5px;

      .img-container {
        width: 48px;
        height: 48px;

        img {
          width: 32px;
          height: auto;
        }
      }

        h4 {
            color: #0A1F4D;
        }

        p {
            color: #2E4379;
        }

        a {
            display: block;
            padding: 4px 22px;
            text-decoration: none;
            font-family: GT Walsheim Pro;
            font-style: normal;
            font-weight: normal;
            font-size: 1rem;
            line-height: 24px;
            text-align: center;
            color: #357DEC;
        }
    }
`;
