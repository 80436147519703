import React from "react";
import Styled from "styled-components";
import { Link } from "react-router-dom";

const CtaSection = () => {
	return (
		<div className="container">
			<div
				style={{
					margin: "102px 0",
					boxShadow: "20px 30px 500px rgba(0, 0, 0, 0.05)",
					borderRadius: "20px",
				}}
			>
				<CtaSectionStyle>
					<img src='https://res.cloudinary.com/startar/image/upload/v1616730730/Startar/pages/home/CtaSection/talentbg_atfbfp.jpg' alt="" />
					<div className="overlay"/>
					<div className="content">
						<h3>Join Startar Talent Pool</h3>
						<p>
							Startar is a community of likeminded student techies who are
							taking over the world.
						</p>
						<Link href="/">Join Starter</Link>
					</div>
				</CtaSectionStyle>
			</div>
		</div>
	);
};

export default CtaSection;

const CtaSectionStyle = Styled.div`
    position: relative;
    width: 100%;
    min-height: 435px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        border-radius: 20px;

        @media (max-width: 600px) {
            height: 100%;
        }
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(111.29deg, rgba(255, 255, 255, 0.53) -1.83%, rgba(255, 255, 255, 0) 109.95%);
        box-sizing: border-box;
        backdrop-filter: blur(30px);
        border-radius: 20px;
    }

    .content {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        z-index: 3;
        width: 90%;
        max-width: 490px;

        h3 {
            text-align: center;
            font-family: 'Recoleta Alt';
            font-style: normal;
            font-weight: 500;
            font-size: 2.5rem;
            line-height: 60px;
            text-align: center;
            color: #0A1F4D;

            @media (max-width: 768px) {
                font-size: 2rem;
            }
        }

        p {
            font-family: 'GT Walsheim Pro', sans-serif;
            font-style: normal;
            font-weight: 300;
            font-size: 1.5rem;
            line-height: 32px;
            text-align: center;
            color: #0A1F4D;

            @media (max-width: 768px) {
                font-size: 1rem;
            }
        }

        a {
            background: #357DEC;
            border-radius: 10px;
            font-family: 'GT Walsheim Pro', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 1rem;
            line-height: 32px;
            text-align: center;
            color: #FFFFFF;
            padding: 12px 40px;
            text-decoration: none;
            margin: 0 auto;
            margin-top: 64px;
            position: relative;
            z-index: 1;

            &:hover {
              background: #1a6be8;
              border-radius: 10px;

              &:after {
                transform: scaleX(1);
              transform-origin: left;
              }
            }

            &:after {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: rgba(0,0,0,0.2);
              border-radius: 10px;
              z-index: -1;
              transition: transform 200ms ease-in;
              transform: scaleX(0);
              transform-origin: right;
            }
        }
    }
`;
