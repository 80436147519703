import React, { useEffect, useState } from "react";

const FlashMessage = ({ message, type }) => {
	const [visibility, setVisibility] = useState(false);
	const [messageOutput, setMessageOutput] = useState("");
	const [status, setStatus] = useState("");

	useEffect(() => {
		setVisibility(true);
		setMessageOutput(message);
		setStatus(status);
		setTimeout(() => {
			setVisibility(false);
		}, 1500);
	}, [message, status]);

	return (
		visibility && (
			<div className={`mt-3 alert alert-${type} position-inherit border-none`}>
				{messageOutput}
			</div>
		)
	);
};

export default FlashMessage;
