/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from "react";
import Styled from "styled-components";
import { useForm } from "react-hook-form";
import axios from "axios";

import { FaRegEnvelope } from "react-icons/fa";
import {
	FiPhone,
	FiFacebook,
	FiTwitter,
	FiLinkedin,
	FiChevronDown,
} from "react-icons/fi";

import { BiError } from "react-icons/bi";
import Header from "../../components/header/Header";
import FlashMessage from "../../components/flashMessage/FlashMessage";

const Contact = () => {
	const [toggle, setToogle] = useState(false);
	const [name, setName] = useState(false);
	const [email, setEmail] = useState(false);
	const [textareas, setTextareas] = useState(false);
	const [loader, setLoader] = useState(false);
	const [error, setError] = useState("");
	const [mainMessage, setMainMessage] = useState("");

	const handleInput = (e) => {
		if (e.target.value !== "") {
			setName(true);
		} else {
			setName(false);
		}
	};
	const handleEmail = (e) => {
		if (e.target.value !== "") {
			setEmail(true);
		} else {
			setEmail(false);
		}
	};
	const handleTextarea = (e) => {
		if (e.target.value !== "") {
			setTextareas(true);
		} else {
			setTextareas(false);
		}
	};

	const handleOnclick = () => {
		setToogle(!toggle);
	};

	const [select, setSelect] = useState("");

	const optionOnclick = (e) => {
		const value = e.target.textContent;
		setSelect(value);
		setToogle(false);
	};

	// Form Validation
	const { register, handleSubmit, errors } = useForm();

	const onSubmit = (data) => {
		setMainMessage("");
		setError("");
		setLoader(true);
		const dataNew = { ...data, inquiry: select };
		// console.log(dataNew);

		const APP_URL = "https://startar-api.herokuapp.com/api/v1/contact/send";

		axios
			.post(APP_URL, dataNew)
			.then((res) => {
				// console.log(res.data);
				setLoader(false);
				setMainMessage(res.data.message);
			})
			.catch((err) => {
				setLoader(false);
				setError(err);
				// console.log(err);
			});
	};

	return (
		<ContactBackground>
			<div className="header-bg">
				<Header />
			</div>

			<div className="container content-bg">
				<div className="row">
					<div className="col-lg-5 col-xl-5 content-1 header-title">
						<h2 className="pe-xl-5 me-xl-5">Get In Touch With Startar Today</h2>
						<p className="pt-1 me-4 me-xl-5 pe-xl-5 header-para">
							Have anything you want to discuss, feel free to reach out to us
							today
						</p>

						<div className="contact-section d-none d-lg-block">
							<div className="contact-us pt-5">
								<div className="text-color d-flex">
									<FaRegEnvelope className="mt-1 icon" />
									<a
										href="mailto:Hello@startar.com"
										className="text-color ms-3 text-decoration-none"
									>
										Hello@startar.com
									</a>
								</div>

								<div className="text-color pt-3 pt-lg-5 mb-lg-4 d-flex">
									<FiPhone className="mt-1 icon" />
									<a
										href="tel:+2340000000000"
										className="text-color ms-3 text-decoration-none"
									>
										+234 000 0000 000
									</a>
								</div>
							</div>

							<div className="socials d-flex my-5">
								<div className="text-color border-custom rounded-circle">
									<FiFacebook className="icon social-link" />
								</div>
								<div className="text-color social-box border-custom rounded-circle mx-5">
									<FiTwitter className="icon social-link" />
								</div>
								<div className="text-color social-box border-custom rounded-circle">
									<FiLinkedin className="icon social-link" />
								</div>
							</div>
						</div>
					</div>

					<div className="contact-section order-1 d-block d-lg-none">
						<div className="contact-us ps-2 ps-sm-0 pt-5">
							<div className="text-color d-flex">
								<FaRegEnvelope className="mt-1 icon" />
								<a
									href="mailto:Hello@startar.com"
									className="text-color ms-3 text-decoration-none"
								>
									Hello@startar.com
								</a>
							</div>

							<div className="text-color pt-3 pt-lg-5 mb-lg-4 d-flex">
								<FiPhone className="mt-1 icon" />
								<a
									href="tel:+2340000000000"
									className="text-color ms-3 text-decoration-none"
								>
									+234 000 0000 000
								</a>
							</div>
						</div>

						<div className="socials d-flex ps-2 ps-sm-0 my-5">
							<div className="text-color border-custom rounded-circle">
								<FiFacebook className="icon social-link" />
							</div>
							<div className="text-color social-box border-custom rounded-circle mx-5">
								<FiTwitter className="icon social-link" />
							</div>
							<div className="text-color social-box border-custom rounded-circle">
								<FiLinkedin className="icon social-link" />
							</div>
						</div>
					</div>

					<div className="col-lg-7 offset-xl-1 col-xl-6 content-2">
						{mainMessage !== "" ? (
							<FlashMessage message={mainMessage} type="success" />
						) : (
							""
						)}
						{error !== "" ? (
							<FlashMessage message={error.message} type="danger" />
						) : (
							""
						)}

						<form className="container" onSubmit={handleSubmit(onSubmit)}>
							<div className="mt-5 mb-3">
								<InputStyle className="placeholder">
									{!name && (
										<label htmlFor="companyName">
											Your Full Name/Company Name <span>*</span>
										</label>
									)}
									<input
										id="companyName"
										text="text"
										onChange={handleInput}
										name="companyName"
										ref={register({
											required: true,
											minLength: 3,
											pattern: /^[A-Za-z]+$/i,
										})}
									/>
								</InputStyle>
								{errors.companyName && errors.companyName.type === "required" && (
									<p className="text-danger error-msg">
										<BiError /> Name is mandatory
									</p>
								)}
								{errors.companyName && errors.companyName.type === "minLength" && (
									<p className="text-danger error-msg">
										<BiError /> Name must not be less than three letters
									</p>
								)}
								{errors.companyName && errors.companyName.type === "pattern" && (
									<p className="text-danger error-msg">
										<BiError /> Name must not contain invalid
										characters(numbers, symbols etc.)
									</p>
								)}
							</div>
							<div className="mb-3">
								<InputStyle className="placeholder">
									{!email && (
										<label htmlFor="email">
											Email Address <span>*</span>
										</label>
									)}
									<input
										id="email"
										text="email"
										onChange={handleEmail}
										name="email"
										ref={register({
											required: true,
											pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
										})}
									/>
								</InputStyle>
								{errors.email && errors.email.type === "required" && (
									<p className="text-danger error-msg">
										<BiError /> Email is mandatory
									</p>
								)}
								{errors.email && errors.email.type === "pattern" && (
									<p className="text-danger error-msg">
										<BiError /> Email is not valid
									</p>
								)}
							</div>

							<div
								name="inquiry"
								className="mb-3 d-flex justify-content-space-between position-relative required"
							>
								<div
									onClick={handleOnclick}
									className="form-control select py-3 required"
									role="button"
									tabIndex={0}
								>
									{select === "" ? (
										<p>
											Select inquiry <span>*</span>
										</p>
									) : (
										select
									)}
									<FiChevronDown className="icon-input" />
								</div>
							</div>
							<div
								className={
									toggle ? "modalBox pt-3 mb-3 show" : "modalBox pt-3 mb-3"
								}
							>
								<div className="container px-5">
									<div className="row justify-content-center">
										<p
											name="general"
											onClick={optionOnclick}
											className="col-12 col-sm-4 select-option py-3 me-sm-3 text-center"
											ref={register}
										>
											General Inquiries
										</p>
										<p
											name="inquiry"
											onClick={optionOnclick}
											className="col-12 col-sm-6 select-option py-3 ms-sm-3 text-center"
										>
											Talent Hiring Inquiries
										</p>

										<p
											name="press&media"
											onClick={optionOnclick}
											className="col-12 col-sm-6 select-option py-3 me-sm-3 text-center"
										>
											Press & Media Coverage
										</p>
										<p
											name="join"
											onClick={optionOnclick}
											className="col-12 col-sm-4 select-option py-3 ms-sm-3 text-center"
										>
											Joining Startar
										</p>

										<p
											name="partnership"
											onClick={optionOnclick}
											className="col-12 col-sm-5 select-option py-3 me-sm-3 text-center"
										>
											Partnerships Inquiries
										</p>
										<p
											name="support"
											onClick={optionOnclick}
											className="col-12 col-sm-5 select-option py-3 ms-sm-3 text-center"
										>
											Support Inquiries
										</p>
									</div>
								</div>
							</div>
							<div className="mb-3">
								<InputStyle className="placeholder">
									{!textareas && (
										<label className="textarea" htmlFor="textarea">
											Write Your Message <span>*</span>
										</label>
									)}
									<textarea
										id="message"
										rows="9"
										onChange={handleTextarea}
										name="message"
										ref={register({
											required: true,
											minLength: 20,
											maxLength: 200,
										})}
									/>
								</InputStyle>
								{errors.message && errors.message.type === "required" && (
									<p className="text-danger error-msg">
										<BiError /> Message cannot be empty
									</p>
								)}
								{errors.message && errors.message.type === "minLength" && (
									<p className="text-danger error-msg">
										<BiError /> Message must not be less than 20 words
									</p>
								)}
								{errors.message && errors.message.type === "maxLength" && (
									<p className="text-danger error-msg">
										<BiError /> Message must not be more than 200 words
									</p>
								)}
							</div>
							<div className="text-end">
								<button
									type="submit"
									name="submit"
									className="btn btn-custom text-white"
								>
									{loader ? (
										<div className="spinner-border text-white" role="status">
											<span className="visually-hidden">Loading...</span>
										</div>
									) : (
										"Send Message"
									)}
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</ContactBackground>
	);
};

export default Contact;

const InputStyle = Styled.div`
    position: relative;
    width: 100%;
    min-height: 50px;
    background: none;
    overflow: none;
    margin-bottom: 1rem;

    input, textarea {
        width: 100%;
        height: 50px;
        padding: 0 16px;
        font-family: GT Walsheim Pro;
        background-color: #F7FAFF;
        border-radius: 10px;
        font-size: 16px;
        font-weight: 500;
        outline: none;
        border: none;
        box-shadow: none;
        color: #2E4379;
    }

    textarea {
        margin-bottom: 16px;
        padding-top: 24px;
        height: 150px;
    }

    
    label {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 16px;
        font-family: GT Walsheim Pro;
        font-size: .8rem;
        font-weight: 400;
        color: #2E4379;
        pointer-events: none;
        
        span {
            color: red;
        }

        &.textarea {
            top: 24px;
            transform: translateY(0);
        }
    }
`;

const ContactBackground = Styled.div`
    width: 100%;
    height: 100%;
    background-color: #071231;
    padding-bottom: 4rem;

  .content-bg {
    background-color: #071231;
    color: #fff;
    height: 100%;
    padding-top: 2rem;
    padding-bottom: 3rem;
  }

  .content-1 {
    h2 {
	  font-family: 'Recoleta Alt';
      font-size: 2.5rem;
      font-weight: 700;
      color: #fff;

      @media (max-width: 375px) { 
        font-size: 2rem;
       }

       @media (min-width: 576px) and (max-width: 1023px) { 
        font-size: 2.4rem;
       }

       @media (max-width: 1024px) { 
        font-size: 2rem;
       }
    }

    .header-para {
      font-family: 'GT Walsheim Pro', sans-serif;
      font-size: 1.4rem;
      font-weight: 300;
      color: #fff;

      @media (max-width: 375px) { 
        font-size: 1.2rem;
       }

   
       @media (max-width: 576px) { 
        font-size: 1.3rem;
        padding-right: 3rem;
       }

       @media (max-width: 1024px) { 
        font-size: 1.3rem;
       }
    }
  }

   a {
      font-family: 'GT Walsheim Pro', sans-serif;
      font-size: 1.2rem;
      font-weight: 300;
      color: #fff;
    }

    .text-color {
      color: #fff;

      .icon {
        font-size: 1.1rem;
            color: #fff;
      }
    }

    .border-custom {
      border: 1px solid #fff;
      border-radius: 50%;
      padding: .7rem .9rem;
      cursor: pointer;

      &:hover {
        border: 1px solid #1A6BE8;
        background-color: #1A6BE8;
        color: #fff;+
      }
    }
    
    .socials {
      padding-top: 6rem;

      @media (max-width: 1024px) { 
      padding-top: 8.2rem;

       }
    }

  .content-2 {
    background-color: #fff;
    border-radius: 10px;
    height: 100%;
    padding-bottom: 1.7rem;

    @media (max-width: 375px) { 
        width: 90%;
        margin: 0 auto;
       }

    .form-control,
    .form-select {
      font-family: GT Walsheim Pro;
      background-color: #F7FAFF;
      border-radius: 10px;
      font-size: .8rem;
      font-weight: 500;
      outline: none;
      border: none;
      box-shadow: none;
      color: #2E4379;

      p {
        font-family: GT Walsheim Pro;
        font-size: .8rem;
        font-weight: 500;
        color: #2E4379;

        span {
          color: red;
        }
      }

      &::placeholder {
        font-family: GT Walsheim Pro;
        font-size: .8rem;
        font-weight: 500;
        color: #2E4379;
      }
    }

    textarea {
      resize: none;
    }

    .btn-custom {
      background-color: #357DEC;
      border-radius: 10px;
      padding: .8rem 2rem;
      border: none;
      outline: none;
      box-shadow: none;
      position: relative;
      z-index: 1;


      &:hover {
      background-color: #1A6BE8;
      border-radius: 10px;

        &:after {
          transform: scaleX(1);
          transform-origin: left;
        }
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.2);
        border-radius: 10px;
        z-index: -1;
        transition: transform 200ms ease-in;
        transform: scaleX(0);
        transform-origin: right;
      }
    }

      p.select {
        color: #2E4379;
        cursor: pointer;
      }

    .icon-input {
      position: absolute;
      right: 0;
      top: 3px;
      font-size: 2rem;
      margin: .7rem;
      cursor: pointer;
    }
    
    .modalBox {
      background-color: #F7FAFF;
      height: 100%;
      width: 100%;
      display: none;

      &.show {
        display: block;
        transition: .3s ease-in-out;
      }

      .select-option {
        background-color: #fff;
        transition: .8s;

        &:hover {
          background-color: #1A6BE8;
          color: #fff;
        }
      }

      }

      p {
        font-family: GT Walsheim Pro;
        font-size: .8rem;
        font-weight: 400;
        color: #2E4379;
        border-radius: 10px;
        cursor: pointer;

      }
    }

    .error-msg {
      font-size: .8rem;
      font-weight: 500;
    }
  }
  

  
 
`;
