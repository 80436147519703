import React from "react";
import Styled from "styled-components";

import Header from "../../components/header/Header";

const Contact = () => {
	return (
		<ContactBackground>
			<div className="header-bg">
				<Header />
			</div>

			<div className="d-flex justify-content-center align-items-center">
				<h1>Coming Soon !!!</h1>
			</div>
		</ContactBackground>
	);
};

export default Contact;

const ContactBackground = Styled.div`
    width: 100%;
    height: 100%;
    background-color: #071231;

 
`;
