const talentArray1 = [
	{
		id: 1,
		image:
			"https://res.cloudinary.com/startar/image/upload/v1616119611/Startar/pages/home/TalentSection/talent_dnklum.jpg",
		name: "David Isiekwene",
		title: "UI/UX Designer",
		brief_title:
			"I've been a UI/UX designer for a year now, and I have had the opportunity of working on projects ranging from ecommerce websites, Mobile appliations and a couple of landing pages. I have proficiency in tools such as Figma, Miro, Adobe Photoshop & Illustrator.",
		job_title_name: "Studio One",
  },
  {
		id: 2,
		image:
			"https://res.cloudinary.com/startar/image/upload/v1616119611/Startar/pages/home/TalentSection/talent_dnklum.jpg",
		name: "David Isiekwene",
		title: "UI/UX Designer",
		brief_title:
			"I've been a UI/UX designer for a year now, and I have had the opportunity of working on projects ranging from ecommerce websites, Mobile appliations and a couple of landing pages. I have proficiency in tools such as Figma, Miro, Adobe Photoshop & Illustrator.",
		job_title_name: "Studio One",
  },
  {
		id: 3,
		image:
			"https://res.cloudinary.com/startar/image/upload/v1616119611/Startar/pages/home/TalentSection/talent_dnklum.jpg",
		name: "David Isiekwene",
		title: "UI/UX Designer",
		brief_title:
			"I've been a UI/UX designer for a year now, and I have had the opportunity of working on projects ranging from ecommerce websites, Mobile appliations and a couple of landing pages. I have proficiency in tools such as Figma, Miro, Adobe Photoshop & Illustrator.",
		job_title_name: "Studio One",
  },
  {
		id: 4,
		image:
			"https://res.cloudinary.com/startar/image/upload/v1616119611/Startar/pages/home/TalentSection/talent_dnklum.jpg",
		name: "David Isiekwene",
		title: "UI/UX Designer",
		brief_title:
			"I've been a UI/UX designer for a year now, and I have had the opportunity of working on projects ranging from ecommerce websites, Mobile appliations and a couple of landing pages. I have proficiency in tools such as Figma, Miro, Adobe Photoshop & Illustrator.",
		job_title_name: "Studio One",
  },
  {
		id: 5,
		image:
			"https://res.cloudinary.com/startar/image/upload/v1616119611/Startar/pages/home/TalentSection/talent_dnklum.jpg",
		name: "David Isiekwene",
		title: "UI/UX Designer",
		brief_title:
			"I've been a UI/UX designer for a year now, and I have had the opportunity of working on projects ranging from ecommerce websites, Mobile appliations and a couple of landing pages. I have proficiency in tools such as Figma, Miro, Adobe Photoshop & Illustrator.",
		job_title_name: "Studio One",
  },
  {
		id: 6,
		image:
			"https://res.cloudinary.com/startar/image/upload/v1616119611/Startar/pages/home/TalentSection/talent_dnklum.jpg",
		name: "David Isiekwene",
		title: "UI/UX Designer",
		brief_title:
			"I've been a UI/UX designer for a year now, and I have had the opportunity of working on projects ranging from ecommerce websites, Mobile appliations and a couple of landing pages. I have proficiency in tools such as Figma, Miro, Adobe Photoshop & Illustrator.",
		job_title_name: "Studio One",
  }
];
export default talentArray1