import React, {useState} from "react";
import Styled from "styled-components";
import {useForm} from "react-hook-form";

import {BiError} from "react-icons/bi";

const Footer = () => {
  const {register, handleSubmit, errors} = useForm();

  const [email, setEmail] = useState(false);

  const handleEmail = (e) => {
    if (e.target.value !== "") {
      setEmail(true);
    } else {
      setEmail(false);
    }
  };

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
		<FooterBg>
			<div className="container pt-5 pb-2">
				<div className="row">
					<div className="col-12 col-md-4 col-lg-3 offset-lg-0 col-xl box-1">
						<div className="py-4 footer-logo-text">
							<img
								src='https://res.cloudinary.com/startar/image/upload/v1616730885/Startar/Components/Header/logo-white_ltfklr.svg'
								className="footer-logo me-3"
								alt="Startar Logo"
							/>
							Startar
						</div>
						<p className="newsletter spacing pt-5 pb-2">NewsLetter</p>
						<form onSubmit={handleSubmit(onSubmit)}>
							<div>
								<InputStyle className="placeholder">
									{!email && (
										<label htmlFor="email">
											Enter Your Email <span>*</span>
										</label>
									)}
									<input
										id="email"
										text="email"
										onChange={handleEmail}
										name="email"
										ref={register({
											required: true,
											pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
										})}
									/>
								</InputStyle>
								{errors.email && errors.email.type === "required" && (
									<p className="text-danger error-msg">
										<BiError /> Email is mandatory
									</p>
								)}
								{errors.email && errors.email.type === "pattern" && (
									<p className="text-danger error-msg">
										<BiError /> Email is not valid
									</p>
								)}
							</div>

							<button
								type="submit"
								className="btn-primary py-3 px-5 mt-2 w-100"
							>
								Sign Me Up
							</button>
						</form>
					</div>

					<div className="col-12 col-md-4 col-lg-3 offset-lg-0 col-xl offset-xl-1 box-2 mt-5 mt-md-0">
						<h6 className="font-weight-bold spacing">Startar</h6>
						<div className="d-block">
							<ul className="list-unstyled pl-0">
								<li className="pt-4 pb-2">
									<a
										href="/"
										className="text-decoration-none footer-text spacing"
									>
										Why We Exist
									</a>
								</li>
								<li className="py-2">
									<a
										href="/"
										className="text-decoration-none footer-text spacing"
									>
										Contact us
									</a>
								</li>
								<li className="py-2">
									<a
										href="/"
										className="text-decoration-none footer-text spacing"
									>
										FAQ
									</a>
								</li>
								<li className="py-2">
									<a
										href="/"
										className="text-decoration-none footer-text spacing"
									>
										Blog
									</a>
								</li>
								<li className="py-2">
									<a
										href="/"
										className="text-decoration-none footer-text spacing"
									>
										Terms & conditions
									</a>
								</li>
							</ul>
						</div>
					</div>

					<div className="col-12 col-md-4 col-lg-3 offset-lg-0 col-xl box-3">
						<h6 className="font-weight-bold spacing">Our Talents</h6>
						<div className="d-block">
							<ul className="list-unstyled pl-0">
								<li className="pt-4 pb-2">
									<a
										href="/"
										className="text-decoration-none footer-text spacing"
									>
										Front-end Developers
									</a>
								</li>
								<li className="py-2">
									<a
										href="/"
										className="text-decoration-none footer-text spacing"
									>
										Back-end Developers
									</a>
								</li>
								<li className="py-2">
									<a
										href="/"
										className="text-decoration-none footer-text spacing"
									>
										Product Deigners
									</a>
								</li>
								<li className="py-2">
									<a
										href="/"
										className="text-decoration-none footer-text spacing"
									>
										Android Developers
									</a>
								</li>
								<li className="py-2">
									<a
										href="/"
										className="text-decoration-none footer-text spacing"
									>
										IOS Developers
									</a>
								</li>
							</ul>
						</div>
					</div>

					<div className="col-12 col-md-4 offset-md-4 col-lg-3 offset-lg-0 col-xl box-4">
						<h6 className="font-weight-bold spacing">Resources</h6>
						<div className="d-block">
							<ul className="list-unstyled pl-0">
								<li className="pt-4 pb-2">
									<a
										href="/"
										className="text-decoration-none footer-text spacing"
									>
										Learn To Code
									</a>
								</li>
								<li className="py-2">
									<a
										href="/"
										className="text-decoration-none footer-text spacing"
									>
										Become A Designer
									</a>
								</li>
								<li className="py-2">
									<a
										href="/"
										className="text-decoration-none footer-text spacing"
									>
										Learn AI/ML
									</a>
								</li>
								<li className="py-2">
									<a
										href="/"
										className="text-decoration-none footer-text spacing"
									>
										Learn Product Management
									</a>
								</li>
								<li className="py-2">
									<a
										href="/"
										className="text-decoration-none footer-text spacing"
									>
										Terms & conditions
									</a>
								</li>
							</ul>
						</div>
					</div>

					<div className="col-12 col-md col-lg offset-lg-3 col-xl-1 offset-xl-0 box-5">
						<h6 className="font-weight-bold spacing">Social</h6>
						<div className="d-block">
							<ul className="list-unstyled pl-0">
								<li className="pt-4 pb-2">
									<a
										href="/"
										className="text-decoration-none footer-text spacing"
									>
										Twitter
									</a>
								</li>
								<li className="py-2">
									<a
										href="/"
										className="text-decoration-none footer-text spacing"
									>
										Linkedin
									</a>
								</li>
								<li className="py-2">
									<a
										href="/"
										className="text-decoration-none footer-text spacing"
									>
										Facebook
									</a>
								</li>
							</ul>
						</div>
					</div>

					<div className="d-flex justify-content-start justify-content-md-end">
						<ul className="list-unstyled d-flex flex-column flex-md-row align-items-start pl-0">
							<li className="py-2 pe-0 pe-md-5">
								<a
									href="/"
									className="text-decoration-none footer-text2 spacing align-self-end"
								>
									© 2021 Startar. All rights reserved.
								</a>
							</li>
							<li className="py-2">
								<a
									href="/"
									className="text-decoration-none footer-text2 spacing"
								>
									Privacy Policy
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</FooterBg>
	);
};

export default Footer;

const InputStyle = Styled.div`
    position: relative;
    width: 100%;
    min-height: 50px;
    background: none;
    overflow: none;
    margin-bottom: 1rem;

    input {
        width: 100%;
        height: 50px;
        padding: 0 16px;
        font-family: GT Walsheim Pro;
        background-color: #F7FAFF;
        border-radius: 10px;
        font-size: 16px;
        font-weight: 500;
        outline: none;
        border: none;
        box-shadow: none;
        color: #2E4379;
    }
    
    label {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 16px;
        font-family: GT Walsheim Pro;
        font-size: .8rem;
        font-weight: 400;
        color: #2E4379;
        
        span {
            color: red;
        }
    }
`;

const FooterBg = Styled.div`
  background-color: #071231;
  color: #fff;

  .box-1 {
    .footer-logo-text {
       font-family: GT Walsheim Pro;
      font-size: 1.8rem;
      font-weight: 300;
    }

    .newsletter {
      font-family: GT Walsheim Pro;
      font-weight: 500;
    }

    form .form-control {
      width: 75%;
      border-radius: 10px;
      border: none;
      padding: 1rem 1.2rem;
      border: none;
      outline: none;
      box-shadow: none;
    }

    form .btn-primary {
      background-color: #357DEC;
      border-radius: 10px;
      border: none;
      outline: none;
      box-shadow: none;
      position: relative;
      z-index: 1;

      &:hover {
        background: #1a6be8;
        border-radius: 10px;

        &:after {
          transform: scaleX(1);
         transform-origin: left;
        }
      }

        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0,0,0,0.2);
          border-radius: 10px;
          z-index: -1;
          transition: transform 200ms ease-in;
          transform: scaleX(0);
          transform-origin: right;
        }
    }

  }

  .box-2,
  .box-3,
  .box-4,
  .box-5 {
     .spacing {
      letter-spacing: 0.02rem;
    }
    
    li a {
      font-family: GT Walsheim Pro;
      font-size: .9rem;
      font-weight: 200;
      color: #fff;  
      opacity: 0.8;

      &:hover {
        font-weight: 400;
      }
    }
  }

  .footer-text2 {
    font-family: GT Walsheim Pro;
      font-size: .9rem;
      font-weight: 200;
      color: #fff;  
      opacity: 0.8;

      &:hover {
        font-weight: 400;
      }
  }

     input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
  }

`;
