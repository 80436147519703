import React, { useState, useEffect } from "react";
import { useWindowScroll } from "react-use";
import Styled from "styled-components";
import { NavLink, Link } from "react-router-dom";

import { GiHamburgerMenu } from "react-icons/gi";
import { GrFormClose } from "react-icons/gr";

const Header = ({ isWhite }) => {
	const [toggle, setToggle] = useState(false);
	const { y: pageYoffset } = useWindowScroll();
	const [visible, setVisibility] = useState(false);
  const [border, setBorder] = useState(false);
  const [view, setView] = useState("");

  const checkOut = () => {
    if (border) {
      const viewed = "navbar navbar-expand-lg navbar-light bg-white new"
      setView(viewed);
    }
    else if (isWhite) {
      const viewed = "navbar navbar-expand-lg navbar-light bg-white"
      setView(viewed);
    }
    else {
      const viewed = "navbar navbar-expand-lg navbar-light"
      setView(viewed);
    }
    
  }

	useEffect(() => {
		if (pageYoffset > 80 || isWhite === true) {
			setVisibility(true);
		} else if (pageYoffset > 80 && isWhite === true) {
			setVisibility(false);
		} else {
			setVisibility(false);
		}

    if(pageYoffset > 80) {
      setBorder(true);
    }

    checkOut();
	}, [pageYoffset, isWhite]);

	const handleOnclick = () => {
		setToggle(!toggle);
	};

	return (
		<HeaderStyle
			className={ view }
		>
			<div className="container">
				<Link
					className="logo"
					style={visible ? { color: "#357DEC" } : { color: "white" }}
					to="/"
				>
					<img
						className="logo-img"
						src={visible ? "https://res.cloudinary.com/startar/image/upload/v1616730878/Startar/Components/Header/logo-blue_sfwcas.svg"
            : "https://res.cloudinary.com/startar/image/upload/v1616730885/Startar/Components/Header/logo-white_ltfklr.svg"}
						alt="Startar Logo"
					/>
					Startar
				</Link>
				<GiHamburgerMenu
					onClick={handleOnclick}
					className={visible ? "toggleIcon change" : "toggleIcon"}
				/>
				<NavLinks
					className={toggle ? "navbar-collapse show" : "navbar-collapse"}
					id="navbarSupportedContent"
				>
					<ul className="navbar-nav ps-4 ps-lg-0 me-auto mx-lg-auto mb-2 mb-lg-0">
						<GrFormClose onClick={handleOnclick} className="icons" />
						<NavLink
							className={visible ? "mx-0 mx-md-2 change" : "mx-0 mx-md-2"}
							activeClassName="active"
							to="/why"
						>
							Why We Exist
						</NavLink>
						<NavLink
							className={visible ? "mx-0 mx-md-2 change" : "mx-0 mx-md-2"}
							activeClassName="active"
							to="/contact"
						>
							Contact Us
						</NavLink>
						<NavLink
							className={visible ? "mx-0 mx-md-2 change" : "mx-0 mx-md-2"}
							activeClassName="active"
							to="/blog"
						>
							Our Blog
						</NavLink>
					</ul>
					<div className="btnGroup mx-auto mx-lg-0 d-flex">
						<a
							href="http://bit.ly/join-startar"
							target="_blank"
							rel="noreferrer"
							className={visible ? "linkBtn change" : "linkBtn"}
						>
							Join Startar
						</a>
						<Link to="/talent" className="mainBtn">
							Hire Talent
						</Link>
					</div>
				</NavLinks>
			</div>
		</HeaderStyle>
	);
};

export default Header;

const HeaderStyle = Styled.nav`
    top: 0;
    height: 15vh;
    display: flex;
    align-items: center;



    .logo {
        display: flex;
        align-items: center;
        font-family: 'GT Walsheim Pro', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 2rem;
        color: #fff;
        text-decoration: none;

        img {
            margin-right: 16px;
            width: 56px; 
            height: 'auto';

            @media (max-width: 768px) {
              width: 30px; 
              height: 'auto';
            }
        }
    }

    &.new {
      position: fixed;
      z-index: 9;
      right: 0;
      left: 0;
      top: 0;
      height: 10vh;
      border-bottom: 1px solid #357DEC;

      .logo {
        font-size: 2rem;

        img {
          width: 40px;
        }
      }
    }

    @media (max-width: 768px) {
      height: 15%;

      &.new {
        height: 10%;

        .logo {
          font-size: 1.7rem;
        }
      }
    }

    .navbar-collapse {
      @media (max-width: 983px) {
        position: fixed;
        width: 50%;
        height: 100vh;
        top: 0;
        right: -100%;
        background: #071231;
        z-index: 32;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        transition: all ease-in .5s;

        &.show {
          right: 0;
        }
      }

      @media (max-width: 375px) {
        width: 85%;
      }
    }

    .toggleIcon {
        display: none;

        @media (max-width: 983px) {
            display: block;
            color: #fff;
            font-size: 2rem;
            cursor: pointer;

            &.change {
              color: #2E4379;
            }
        }
    }
`;

const NavLinks = Styled.div`
    position: relative;

    .icons {
      display: none;
      
      @media (max-width: 980px) {
        position: absolute;
        display: block;
        top: 32px;
        right: 32px;
        background: #fff;
        font-size: 24px;
        cursor: pointer;
      }
    }

    ul {
        a {
            color: #fff;
            text-decoration: none;
            font-family: 'GT Walsheim Pro', sans-serif;
            font-weight: 300;
            font-size: 1rem;
            line-height: 24px;
            padding: 7px 0;
            /* transition: all .3s ease-in; */

            &:last-child {
              margin-right: 0;
            }

            &.change {
              color: #2E4379;

              @media (max-width: 768px) {
                color: #fff;
              }
            }

            &.active {
              font-weight: bold;
              color: #fff;
              border-bottom: 2px solid #357DEC;
            }

            &.active.change {
              font-weight: bold;
              color: #357DEC;
              border-bottom: 2px solid #2E4379;

              @media (max-width: 768px) {
                color: #fff;
              }
            }

            &:hover {
                font-weight: bold;
                color: #fff;
                border-bottom: 2px solid #357DEC;
            }

            &.change:hover {
              font-weight: bold;
              color: #357DEC;
              border-bottom: 2px solid #2E4379;
            }

            @media (max-width: 980px) {
              /* text-align: center; */
              margin-bottom: 24px;
            }
        }
    }

    .btnGroup {
        align-items: center;

        
        .linkBtn {
          color: #fff;
          text-decoration: underline;
          font-family: 'GT Walsheim Pro', sans-serif;
          font-weight: 500;
          font-size: 1.125rem;
          line-height: 24px;
          
          &:hover {
            color: #357DEC;
            text-decoration: underline;
          }
          
          &.change {
            color: #357DEC;
            
            &:hover {
              color: #2E4379;
            }

            @media (max-width: 768px) {
              color: #fff;

              &:hover {
                color: #fff;
              }
            }
          }
        }
        
        .mainBtn {
          background: #357DEC;
          padding: 0.75rem 2.5rem;
          border-radius: 10px;
          color: #fff;
          margin-left: 32px;
          font-family: 'GT Walsheim Pro', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 1rem;
          text-decoration: none;
          position: relative;
          z-index: 1;

          &:hover {
            background: #1a6be8;
            border-radius: 10px;

             &:after {
              transform: scaleX(1);
              transform-origin: left;
              }
          }

           &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,0.2);
            border-radius: 10px;
            z-index: -1;
            transition: transform 200ms ease-in;
            transform: scaleX(0);
            transform-origin: right;
          }
        }

        @media (max-width: 980px) {
          flex-direction: column;
  
          .mainBtn {
            margin-left: 0;
            margin-top: 24px;
          }
        }

      }
    `;
