import React from "react";
import Styled from "styled-components";

import Header from "../../components/header/Header";
import PhotoGrid from "../../components/photogrid/PhotoGrid";
import TalentSection from "../../components/talentSection/TalentSection";
import Steps from "../../components/steps/Steps";
import Footer from "../../components/footer/Footer";

const Contact = () => {
	return (
		<ContactBackground>
			<div className="header-bg">
        <Header isWhite={true}/>
			</div>

			<div className="d-flex justify-content-center align-items-center mt-3 mb-4">
				<h6 className="title position-relative">
					Startar
					<span className="line position-absolute mx-3"/>
					<span className="span2">Why We Exist</span>
				</h6>
			</div>

			<div className="container mt-1 mb-5">
				<div className="d-sm-flex justify-content-center align-items-center sub-title">
					<div className="content-1 text-center span-color">
						<p>Our Talent Reach</p>
					</div>

					<div className="content-2">
						<h2 className="ps-4 text-center">
							A <span className="span-color">GLOBAL</span> Pool Of Talents
						</h2>
					</div>
				</div>
			</div>

			<section className="row">
				<div className="section-2 col-md-12 col-lg offset-lg-1 col-xl offset-xl-1 d-lg-block d-xl-flex pt-3">
					<div className="span-color">
						<p className="title-1">Here’s What We Know</p>
						<img src='https://res.cloudinary.com/startar/image/upload/v1616731252/Startar/pages/whyWeExist/why-zigzag-2_oixzke.png' className="" alt="zigzag-img" />
					</div>

					<div className="sub-section-2">
						<h3>
							<span className="span-color">“Students” </span> Does Not Equate To
							Incompetent Or Inexperienced
						</h3>
						<p>
							Our well structured recruitment process not only ensures that we
							onboard very technically skilled talents, we are also very
							partficular about onboarding talents with professsional work
							experience, top-level time management skills, good team
							collaboration skills and excellent communication skills.
						</p>
					</div>
				</div>

				<div className="col-md-12 col-lg col-xl img1-box mt-4">
					<img src='https://res.cloudinary.com/startar/image/upload/v1616731253/Startar/pages/whyWeExist/why-img-1_thunov.png' className="img1" alt="img1" />
				</div>
			</section>

			<section className="section-3 mt-sm-5 pt-sm-1">
				<div className="section-3-header d-flex justify-content-center align-items-center">
					<h1>
						Our World Is Evolving, Work Culture Is Evolving And Changing, Remote
						Work Is Becoming The Norm...
						<br />
						Here’s Where
						<span className="span-color"> Startar</span> Comes In.
					</h1>
				</div>

				<div className="row section-3-para justify-content-center align-items-center">
					<div className="col-12 col-sm">
						<p>
							Our well structured recruitment process not only ensures that we
							onboard very technically skilled talents, we are also very
							partficular about onboarding talents with professsional work
							experience, top-level time management skills, good team
							collaboration skills and excellent communication skills.
						</p>
					</div>

					<div className="col-12 col-sm">
						<p>
							Our well structured recruitment process not only ensures that we
							onboard very technically skilled talents, we are also very
							partficular about onboarding talents with professsional work
							experience, top-level time management skills, good team
							collaboration skills and excellent communication skills.
						</p>
					</div>
				</div>
			</section>
			<PhotoGrid />
			<TalentSection />
			<Steps />
			<Footer />
		</ContactBackground>
	);
};

export default Contact;

const ContactBackground = Styled.div`
    width: 100%;
    height: 100%;

    background-color: #F7FAFF;

    .header-bg {
      background-color: #F7FAFF;
      height: 15vh;
    }

    .span-color {
        color: #357DEC;
      }

    .title {
      font-family: Recoleta;
      font-size: 1rem;
      font-weight: 600;
      color: #0A1F4D;

        .line {
        width: 2rem;
        height: 2px;
        background-color: #0A1F4D;
        top: 10px;
      }

      .span2 {
        margin-left: 4rem;
      }
    }

    .sub-title {

      .content-1 {
        font-family: GT Walsheim Pro;
        color: #357DEC;
      }

      .content-2 {

          h2 {
            font-family: Recoleta;
            font-weight: 600;
            width: 14rem;
            line-height: 1.7;

            @media (max-width: 375px) {
              width: 100%;
            }
          }
      }
    }

    .img1-box {
      height: 330px;
      overflow: hidden;

        .img1 {
        height: auto;
        width: 100%;
      }

    }

  .section-2 {
    border-top: 2px solid #ddd;

    @media (max-width: 768px) {
      margin: 0 auto;
      width: 100%;
      max-width: 700px;
    }

    @media (max-width: 576px) {
      width: 90%;
    }

    .title-1 {
      width: 10rem;
    }
  }

    .sub-section-2 {
      margin: 0 2rem;

      @media (max-width: 1024px) {
        margin: 0;
      }

      h3 {
        font-family: Recoleta;
        font-size: 2rem;
        font-weight: 600;
        margin-right: 2rem;
        line-height: 1.4;

        @media (max-width: 1024px) {
          font-size: 1.6rem;
        }

        }

        p {
          font-family: GT Walsheim Pro;
          font-size: .85rem;
          font-weight: 300;
        }
    }

    .section-3 {
      .section-3-header,
      .section-3-para {
        margin: 0 16.5rem;

         @media (max-width: 1024px) {
            margin: 0 10rem;
          }

          @media (max-width: 768px) {
            margin: 0 2rem;
          }

        h1 {
          font-family: Recoleta;
          font-size: 3rem;
          font-weight: 600;
          color: #0A1F4D;

          @media (max-width: 1024px) {
            font-size: 2.5rem;
          }

          @media (max-width: 768px) {
            font-size: 1.5rem;
          }
          @media (max-width: 375px) {
            font-size: 1.1rem;
            line-height: 1.5;
          }

        }

        p {
          font-family: GT Walsheim Pro;
          font-size: .85rem;
          font-weight: 300;
          color: #2E4379;
        }
      }

    }

`;
