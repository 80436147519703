import React, { useEffect } from "react";

import AOS from "aos";
import HeroSection from "../../components/hero/HeroSection";
import Stack from "../../components/stack/Stack";
import CtaSection from "../../components/ctaSection/CtaSection";
import TalentSection from "../../components/talentSection/TalentSection";
import Steps from "../../components/steps/Steps";
import MapSection from "../../components/mapSection/MapSection";
import Footer from "../../components/footer/Footer";

const Home = () => {
	useEffect(() => {
		AOS.init();
	}, []);

	return (
		<>
			<HeroSection />
			<Stack />
			<MapSection />
			<CtaSection />
			<TalentSection />
			<Steps />
			<Footer />
		</>
	);
};

export default Home;
