import React from "react";
import Styled from "styled-components";
import { Link } from "react-router-dom";

const Steps = () => {
	return (
		<StepBlock>
			<div className="title">
				<h1>Start Hiring In A Jiffy</h1>
				<p>
					We have simplified our hiring process, which means less stress for you
					and your hiring team.
				</p>
			</div>
			<div className="gridContainer">
				<div className="gridCard">
					<div className="num">01</div>
					<h3>Speak To Our Representative</h3>
					<p>
						We will work hand-in-hand with you to understand your needs and
						project goals
					</p>
					<div className="line" />
				</div>
				<div className="gridCard">
					<div className="num">02</div>
					<h3>Get Perfect Talent Fit</h3>
					<p>
						A talent who fits perfectly to your needs will be assigned to you
						within two working days
					</p>
					<div className="line" />
				</div>
				<div className="gridCard">
					<div className="num">03</div>
					<h3>Top Notch Relationship</h3>
					<p>
						Our team will work closely with you and the selected talent to
						ensure your needs are met
					</p>
				</div>
			</div>
			<div className="buttonContainer">
				<Link href="https://twitter.com/dev_sproff" target="_blank">
					Hire Talent
				</Link>
			</div>
		</StepBlock>
	);
};

export default Steps;

const StepBlock = Styled.div`
    width: 100%;
    min-height: 400px;
    background-color: #F7FAFF;
    padding: 80px 0;

    .title {
        width: 90%;
        max-width: 450px;
        margin: 0 auto;

        h1 {
            font-family: 'Recoleta Alt';
            font-style: normal;
            font-weight: bold;
            font-size: 2rem;
            line-height: 44px;
            text-align: center;
            color: #0A1F4D;
        }

        p {
            font-family: 'GT Walsheim Pro', sans-serif;
            font-style: normal;
            font-weight: 300;
            font-size: 1rem;
            line-height: 32px;
            text-align: center;
            color: #2E4379;
            padding-top: 16px;
        }
    }

    .buttonContainer {
        width: 100%;
        display: flex;
        justify-content: center;

        a {
            padding: 0.75rem 2.5rem;
            background: #357DEC;
            color: #fff;
            text-decoration: none;
            border-radius: 10px;
            margin-top: 64px;
            font-family: 'GT Walsheim Pro', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 32px;
            text-align: center;
            color: #FFFFFF;
            position: relative;
            z-index: 1;

            &:hover {
              background: #1a6be8;
               border-radius: 10px;

                &:after {
                  transform: scaleX(1);
                transform-origin: left;
                }
            }

              &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0,0,0,0.2);
                border-radius: 10px;
                z-index: -1;
                transition: transform 200ms ease-in;
                transform: scaleX(0);
                transform-origin: right;
              }
        }
    }

    .gridContainer {
        width: 90%;
        max-width: 900px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 54px;

        @media (max-width: 785px) {
            flex-direction: column;
        }

        .gridCard {
            width: 235px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            position: relative;

            .num {
                width: 40px;
                height: 40px;
                border: 1px solid #357DEC;
                border-radius: 50%;
                font-family: 'GT Walsheim Pro', sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                color: #357DEC;
                margin-bottom: 15px;
            }

            h3 {
                font-family: 'Recoleta Alt';
                font-style: normal;
                font-weight: 500;
                font-size: 1.125rem;
                line-height: 24px;
                display: flex;
                align-items: center;
                text-align: center;
                color: #0A1F4D;
            }

            p {
                font-family: 'GT Walsheim Pro', sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 0.875rem;
                line-height: 20px;
                text-align: center;
                color: #2E4379;
                margin-top: 7px;
            }

            .line {
                position: absolute;
                top: 20px;
                left: 80%;
                width: 11.8rem;
                height: 1px;
                background: #357DEC;

                &:before {
                    content: '';
                    position: absolute;
                    top: -4.8px;
                    left: 93.6%;
                    width: 10px;
                    height: 10px;
                    border-right: 1px solid #357DEC;
                    border-bottom: 1px solid #357DEC;
                    transform: rotate(-45deg);
                }

                @media (max-width: 900px) {
                    width: 9rem;
                }
                
                @media (max-width: 785px) {
                    position: static;
                    width: 1px;
                    height: 40px;
                    margin-bottom: 32px;

                    &:before {
                        top: 83%;
                        left: 48%;
                        width: 10px;
                        height: 10px;
                        border-right: 1px solid #357DEC;
                        border-bottom: 1px solid #357DEC;
                        transform: rotate(45deg);
                    }
                }
            }
        }
    }
`;
