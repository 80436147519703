import React from "react";
import Styled, {keyframes} from "styled-components";

const Loader = () => {
    return (
        <LoaderStyle>
            <div className="logo-container">
                <img src="https://res.cloudinary.com/startar/image/upload/v1616730878/Startar/Components/Header/logo-blue_sfwcas.svg" alt="logo"/>
                <div className="rotator" />
            </div>
        </LoaderStyle>
    )
}

export default Loader;

const LoaderAnimation = keyframes`
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
`;

const LoaderStyle = Styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #071231;

    .logo-container {
        position: relative;
        width: 130px;
        height: 130px;
        border-radius: 50%;
        background: #F7FAFF;
        display: flex;
        justify-content: center;
        align-items: center;
        
        .rotator {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            border-top: 4px solid #357DEC;
            animation-name: ${LoaderAnimation};
            animation-duration: .5s;
            animation-iteration-count: infinite;
        }
    }

`;