import React from "react";
import Styled from "styled-components";

const PhotoGrid = () => {
	return (
		<PhotoGridStyle>
			<div className="gridItem one">
				<img src='https://res.cloudinary.com/startar/image/upload/v1616731253/Startar/pages/whyWeExist/why-box-1_milncf.png' alt="why one" />
			</div>
			<div className="gridItem two">
				<img src='https://res.cloudinary.com/startar/image/upload/v1616731253/Startar/pages/whyWeExist/why-box-2_mqpkzg.png' alt="Why two" />
			</div>
			<div className="gridItem three">
				<img src='https://res.cloudinary.com/startar/image/upload/v1616731254/Startar/pages/whyWeExist/why-box-3_si1j7s.png' alt="Why three" />
			</div>
			<div className="gridItem four">
				<img src='https://res.cloudinary.com/startar/image/upload/v1616731255/Startar/pages/whyWeExist/why-box-4_zgkvge.png' alt="Why four" />
			</div>
			<div className="gridItem five">
				<h6>Our Mission</h6>
				<h2>
					<span>“Students”</span> Does Not Equate To Incompetent Or
					Inexperienced
				</h2>
				<p>
					Our well structured recruitment process not only ensures that we
					onboard very technically skilled talents, we are also very partficular
					about onboarding talents with professsional work experience, top-level
					time management skills, good team collaboration skills and excellent
					communication skills.
				</p>
			</div>
			<div className="gridItem six">
				<img src='https://res.cloudinary.com/startar/image/upload/v1616731254/Startar/pages/whyWeExist/why-box-5_hhztiq.png' alt="Why five" />
			</div>
		</PhotoGridStyle>
	);
};

export default PhotoGrid;

const PhotoGridStyle = Styled.div`
    width: 100%;
    min-height: 400px;
    display: grid;
    grid-column-gap: 20px;
    grid-row-gap: 16px;
    grid-template-columns: 50% 50%;
    margin-top: 5.5rem;
    margin-bottom: 5.75rem;

    @media (max-width: 768px) {
        margin-top: 5rem;
        margin-bottom: 4.25rem;
    }

    @media (max-width: 768px) {
        grid-template-columns: 100%;
    }

    .gridItem {
        &.one {
            height: 400px;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;

            img {
                width: 314px;
                height: auto;

                @media (max-width: 490px) {
                    width: 100%;
                    height: auto;
                }
            }
        }

        &.two {
            height: 478px;
            overflow: hidden;

            @media (max-width: 475px) {
                height: 330px;
                display: none;
            }

            img {
                height: 100%;
                width: auto;
            }
        }

        &.three {
            margin-top: -80px;
            height: 719px;
            overflow: hidden;

            @media (max-width: 768px) {
                margin-top: 0;
            }

            @media (max-width: 475px) {
                height: 500px;
                display: none;
            }

            img {
                height: 100%;
                width: auto;
            }
        }

        &.four {
            height: 936px;

            @media (max-width: 475px) {
                height: 630px;
                display: none;
            }

            img {
                height: 100%;
                width: auto;
                overflow: hidden;
            }
        }

        &.five {
            height: 548px;
            margin-top: -297px;
            padding-left: 12%;
            padding-top: 10rem;
            padding-right: 12%;

            @media (max-width: 768px) {
                margin-top: 0;
                padding-left: 8%;
                padding-right: 8%;
                padding-top: 6rem;
            }

            h2 {
                color: #0A1F4D;
                font-family: Recoleta;
                font-size: 2.5rem;
                font-weight: 700;
                line-height: 3rem;
                margin-bottom: 1rem;

                @media (max-width: 450px) {
                    font-size: 1.7rem;
                    line-height: 2rem;
                }

                span {
                    color: #357DEC;
                }
            }

            h6 {
                color: #357DEC;
                font-family: GT Walsheim Pro;
                font-size: 1rem;
                font-weight: 700;
                margin-bottom: 1.5rem;
            }

            p {
                color: #2E4379;
                font-family: GT Walsheim Pro;
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.5rem;
            }
        }

        &.six {
            display: flex;
            justify-content: flex-end;

            @media (max-width: 468px) {
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
`;
