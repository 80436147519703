import React from "react";
import Styled from "styled-components";
import { Link } from "react-router-dom";
import Header from "../header/Header";

const HeroSection = () => {
	return (
		<HeroStyle
			style={{
				backgroundImage: `url(https://res.cloudinary.com/startar/image/upload/v1616730085/Startar/pages/home/HeroSection/HeroBg_tbvo39.jpg)`,
			}}
		>
			<Header />

			<div className="heroContainer">
				<div className="content">
					<h1>
						Hire The Top
						<span>
							{" "}
							1%{" "}
							<img
								src="https://res.cloudinary.com/startar/image/upload/v1616730117/Startar/pages/home/HeroSection/rough-line_nlph1m.png"
								alt="bottom text line"
							/>
						</span>
						Of Freelance Tech Students
					</h1>
					<p>
						Startar is an exclusive community of the very best tech professional
						freelancers still in university and colleges.
					</p>

					<div className="btnGroup">
						<Link className="mainBtn btnPrimary" to="/talent">
							Hire Talent
						</Link>
						<a className="mainBtn btnOutline" href="http://bit.ly/join-startar">
							Join Starter
						</a>
					</div>
				</div>
			</div>
		</HeroStyle>
	);
};

export default HeroSection;

const HeroStyle = Styled.div`
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-position: center;

    .heroContainer {
        width: 100%;
        height: calc(100vh - 15vh);
        display: flex;
        justify-content: center;
        align-items: center;

        .content {
            width: 100%;
            max-width: 745px;

            h1 {
                font-family: 'Recoleta Alt';
                font-style: normal;
                font-weight: bold;
                font-size: 3.5rem;
                line-height: 76px;
                text-align: center;
                color: #fff;

                @media (max-width: 768px) {
                  font-size: 2rem;
                  line-height: 50px;
                }

                span {
                    color: #357DEC;
                    position: relative;

                    img {
                      position: absolute;
                      bottom: 0;
                      left: 45%;
                      transform: translateX(-50%);
                      width: 70px;

                      @media(max-width: 500px) {
                        width: 45px;
                      }
                    }
                }
            }

            p {
                font-family: 'GT Walsheim Pro', sans-serif;
                font-style: normal;
                font-weight: 300;
                font-size: 1.5rem;
                line-height: 32px;
                text-align: center;
                color: #FAFAFA;
                margin-top: 16px;

                @media (max-width: 768px) {
                  font-size: 1.1rem;
                  padding: 0 16px;
                  line-height: 24px;
                }
            }

            .btnGroup {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 5rem;

                .mainBtn {
                    padding: 1rem 3rem;
                    font-family: 'GT Walsheim Pro', sans-serif;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 1rem;
                    line-height: 24px;
                    text-align: center;
                    color: #FFFFFF;
                    border-radius: 10px;
                    text-decoration: none;

                    @media (max-width: 768px) {
                      padding: .7rem 2rem;
                      font-size: 1rem;
                    }

                    &.btnPrimary {
                        background: #357DEC;
                        margin-right: 1.17rem;
                        position: relative;
                        z-index: 1;

                        @media (max-width: 768px) {
                          margin-right: .7rem;
                        }

                        &:hover {
                          background: #1a6be8;

                          &:after {
                            transform: scaleX(1);
                            transform-origin: left;
                          }
                        }

                         &:after {
                          content: '';
                          position: absolute;
                          top: 0;
                          left: 0;
                          width: 100%;
                          height: 100%;
                          background: rgba(0,0,0,0.2);
                          border-radius: 10px;
                          z-index: -1;
                          transition: transform 200ms ease-in;
                          transform: scaleX(0);
                          transform-origin: right;
                        }
                    }

                    &.btnOutline {
                        border: 1px solid #357DEC;
                        margin-left: 1.17rem;
                        color: #357DEC;
                        position: relative;
                        z-index: 1;

                        @media (max-width: 768px) {
                          margin-left: .7rem;
                        }

                        &:hover {
                          background: #1a6be8;
                          border: 1px solid rgba(0,0,0,0.5);
                          color: #fff;

                           &:after {
                            transform: scaleX(1);
                            transform-origin: left;
                          }
                        }

                        &:after {
                          content: '';
                          position: absolute;
                          top: 0;
                          left: 0;
                          width: 100%;
                          height: 100%;
                          background: rgba(0,0,0,0.2);
                          border-radius: 10px;
                          z-index: -1;
                          transition: transform 200ms ease-in;
                          transform: scaleX(0);
                          transform-origin: right

                          &:hover {
                          border: 1px solid transparent;
                        }
                    }
                }
            }
        }
    }
`;
