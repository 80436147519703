import React, { useState, useEffect } from "react";

// Fonts
import "./fonts/header/stylesheet.css";
import "./fonts/support/stylesheet.css";

// Css
import "bootstrap/dist/css/bootstrap.min.css";
import Routes from "./routes/Routes";

import "aos/dist/aos.css";
import Loader from "./components/loader/Loader";

const App = () => {
	const [loader, setLoader] = useState(false);

	useEffect(() => {
		setLoader(true);
	}, []);

	return (
		<>
			{loader ? (
				<Routes />
			) : (
				<Loader />
			)}
		</>
	);
};

export default App;
