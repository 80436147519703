/* eslint-disable arrow-body-style */
import React from "react";
import Styled from "styled-components";

import { Link } from "react-router-dom";
import talentArray1 from "../../services/Talent";

const TalentSection = () => {
	return (
		<TalentStyles>
			<div className="title">
				<h1>Some Of Our Top Talents</h1>
				<p data-aos-delay="500">
					We have a pool of the very finest students who have established
					themselves as forces in the global tech scene.
				</p>
			</div>
			<div className="container">
				<div className="row">
					{talentArray1.map((talentArray) => (
						<div key={talentArray1.id} className="col-sm-6 col-md-4 mb-5">
							<div className="card">
								<div className="card-img">
									<img
										className="img-fluid"
										src={talentArray.image}
										alt="talent pool"
									/>
								</div>
								<div className="card-content">
									<div className="content">
										<h4>
											{talentArray.name}
											<br />
											<span className="title">{talentArray.title}</span>
										</h4>
										<p className="brief-title py-3">
											{talentArray.brief_title}
										</p>
										<p className="job-title">
											Previously at <br />
											<span className="job-title-name">
												{talentArray.job_title_name}
											</span>
										</p>
									</div>
								</div>
							</div>
						</div>
					))}
					<div className="buttonContainer">
						<Link to="/talents">View All Talents</Link>
					</div>
				</div>
			</div>
		</TalentStyles>
	);
};

export default TalentSection;

const TalentStyles = Styled.div`
    width: 100%;
    min-height: 400px;
    background: #F7FAFF;
    padding: 80px 0;

    .title {
        width: 90%;
        max-width: 500px;
        margin: 0 auto;
        margin-bottom: 81px;

        h1 {
            font-family: 'Recoleta Alt';
            font-style: normal;
            font-weight: 700;
            font-size: 2rem;
            line-height: 44px;
            text-align: center;
            color: #0A1F4D;
        }

        p {
            font-family: 'GT Walsheim Pro', sans-serif;
            font-style: normal;
            font-weight: 300;
            font-size: 1.5rem;
            line-height: 32px;
            text-align: center;
            color: #2E4379;
            padding-top: 16px;
        }
    }

    .card {
        border: none;
        padding: 8px;
        background: #fff;

        &:hover {
            background: #FFFFFF;
            box-shadow: 4px 10px 18px rgba(141, 141, 141, 0.09);
            border-radius: 5px;
        }

        .card-img {
            img {
                width: 100%;
                height: auto;
            }
        }

        .card-content {
            background: none;
            border: none;
            box-shadow: none;
            display: flex;
            justify-content: space-between;
            padding: 16px 0;

            .content {
                h4 {
                    font-family: 'Recoleta Alt';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 1rem;
                    line-height: 24px;
                    color: #0A1F4D;

                    .title {
                      font-family: 'GT Walsheim Pro', sans-serif;
                      font-size: .8rem;
                      font-weight: 300;
                    }
                  }

                   .brief-title {
                      font-family: 'GT Walsheim Pro', sans-serif;
                      font-style: normal;
                      font-weight: 300;
                      font-size: .9rem;
                      line-height: 1.9;
                      color: #333;
                      }

                   .job-title {
                        color: #071231;
                        font-size: .8rem;
                        font-weight: 300;

                        .job-title-name {
                          color: #2E4379;
                          font-size: .89rem;
                          font-weight: 400;
                        }
                      }
            }
        }
    }

    .buttonContainer {
      width: 100%;
      display: flex;
      justify-content: center;

              a {
                  padding: 0.75rem 2.5rem;
                  background: #357DEC;
                  color: #fff;
                  text-decoration: none;
                  border-radius: 10px;
                  margin-top: 64px;
                  font-family: 'GT Walsheim Pro', sans-serif;
                  font-style: normal;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 32px;
                  text-align: center;
                  color: #FFFFFF;
                  position: relative;
                  z-index: 1;

                  &:hover {
                    background: #1a6be8;
                    border-radius: 10px;

                      &:after {
                        transform: scaleX(1);
                      transform-origin: left;
                      }
                  }

                    &:after {
                      content: '';
                      position: absolute;
                      top: 0;
                      left: 0;
                      width: 100%;
                      height: 100%;
                      background: rgba(0,0,0,0.2);
                      border-radius: 10px;
                      z-index: -1;
                      transition: transform 200ms ease-in;
                      transform: scaleX(0);
                      transform-origin: right;
                    }
              }
          }              
`;
