import React from "react";
import Styled from "styled-components";

const MapSection = () => {
	return (
		<MapSectionStyle>
			<div className="container">
				<div className="content">
					<h1>We Boast A Global Reach Of The Best Tech Talents.</h1>
					<p>
						We have a pool of the very finest students who have established
						themselves as forces in the global tech scene.
					</p>
					<button type="submit">Hire Talent</button>
				</div>
			</div>
			<img className="map" src='https://res.cloudinary.com/startar/image/upload/v1616730432/Startar/pages/home/MapSection/map_qphari.svg' alt="world map" />
			<div className="talent talent1">
				<img src='https://res.cloudinary.com/startar/image/upload/v1616730432/Startar/pages/home/MapSection/talent1_qgrvyl.png' alt="talent location" />
			</div>
			<div className="talent talent2">
				<img src='https://res.cloudinary.com/startar/image/upload/v1616730432/Startar/pages/home/MapSection/talent2_mygf2x.png' alt="talent location" />
			</div>
			<div className="talent talent3">
				<img src='https://res.cloudinary.com/startar/image/upload/v1616730432/Startar/pages/home/MapSection/talent3_qwbbvq.png' alt="talent location" />
			</div>
			<div className="talent talent4">
				<img src='https://res.cloudinary.com/startar/image/upload/v1616730433/Startar/pages/home/MapSection/talent5_ewfh3y.png' alt="talent location" />
			</div>
			<div className="talent talent5">
				<img src='https://res.cloudinary.com/startar/image/upload/v1616730431/Startar/pages/home/MapSection/talent7_q8tpiy.png' alt="talent location" />
			</div>
			<div className="talent talent6">
				<img src='https://res.cloudinary.com/startar/image/upload/v1616730431/Startar/pages/home/MapSection/talent6_ghprkz.png' alt="talent location" />
			</div>
			<div className="talent talent7">
				<img src='https://res.cloudinary.com/startar/image/upload/v1616730432/Startar/pages/home/MapSection/talent4_xtbkml.png' alt="talent location" />
			</div>
			<img className="dots" src='https://res.cloudinary.com/startar/image/upload/v1616730432/Startar/pages/home/MapSection/Dot_skvkr8.png' alt="dots patterns" />
		</MapSectionStyle>
	);
};

export default MapSection;

const MapSectionStyle = Styled.div`
    width: 100%;
    min-height: 700px;
    display: flex;
    align-items: center;
    background: #071231;
    position: relative;
    overflow: hidden;

    .dots {
        position: absolute;
        top: 10px;
    }

    .map {
        width: 120%;
        height: auto;
        position: absolute;
        right: -55%;
        top: 45%;
        transform: translateY(-50%);

    }
    
    @media (max-width: 990px) {
        // min-height: 1300px;
        flex-direction: column;
        // overflow: scroll;

        .map {
            position: static;
            width: 100%;
            margin-top: 150px;
        }

        .content {
            margin: 3rem 0;
        }
    }

    @media (max-width: 465px) {
        .map {
            width: 180%;
            overflow: hidden;
            margin-left: 200px;
        }
    }

    .talent {
        position: absolute;
        width: 5rem;
        height: 5rem;
        background: #071231;
        border: 2px solid #357DEC;
        border-radius: 50%;
        // padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        
        &.talent1 {
            top: 5%;
            right: 40%;

            img {
                width: 230%;
                height: auto;
                margin-top: 3.3rem;
                margin-right: auto;
            }
        }

        &.talent2 {
            top: 15%;
            right: 30%;

            img {
                width: 230%;
                height: auto;
                margin-top: 3.4rem;
            }
        }

        &.talent3 {
            top: 18%;
            right: 3%;

            img {
                width: 230%;
                height: auto;
                margin-top: 3.3rem;
                margin-left: .1rem;
            }
        }

        &.talent4 {
            top: 37%;
            right: 7%;
            
            img {
                width: 230%;
                height: auto;
                margin-top: 3.3rem;
            }
        }

        &.talent5 {
            top: 65%;
            right: 4%;

            img {
                width: 230%;
                height: auto;
                margin-top: 2.4rem;
                margin-left: .1rem;
            }
            
        }

        &.talent6 {
            top: 77%;
            right: 30%;

            img {
                width: 230%;
                height: auto;
                margin-top: .2rem;
                // margin-left: .1rem;
            }
        }

        &.talent7 {
            top: 44%;
            right: 33%;

            img {
                width: 230%;
                height: auto;
                margin-top: 3.4rem;
                margin-left: .1rem;
            }
        }

        @media ( max-width: 990px ) {
            width: 3rem;
            height: 3rem;

            &.talent1 {
                top: 55%;
                right: 80%;
                
                img {
                    margin-top: 2rem;
                }
            }

            &.talent2 {
                top: 57%;
                right: 67%;

                img {
                    margin-top: 1.9rem;
                }
            }

            &.talent3 {
                top: 55%;
                right: 45%;
                
                img {
                    margin-top: 2rem;
                }
            }

            &.talent4 {
                top: 65%;
                right: 75%;

                img {
                    margin-top: 2rem;
                }
            }

            &.talent5 {
                top: 65%;
                right: 53%;
                
                img {
                    margin-top: 1.5rem;
                    /* margin-left: 0; */
                }
            }

            &.talent6 {
                top: 75%;
                right: 70%;

                img {
                    width: 230%;
                    height: auto;
                    margin-top: .2rem;
                }
            }

            &.talent7 {
                top: 73%;
                right: 45%;

                img {
                    margin-top: 1.9rem;
                }
            }
        }

        @media ( max-width: 465px ) {

            &.talent1 {
                right: 85%;
            }

            &.talent2 {
                right: 67%;
            }

            &.talent3 {
                right: 25%;
            }

            &.talent4 {
                right: 70%;
            }

            &.talent5 {
                right: 28%;
            }

            &.talent6 {
                right: 60%;
            }

            &.talent7 {
                right: 25%;
            }
        }

        @media ( max-width: 350px ) {

            &.talent1 {
                top: 62%;
                right: 80%;
            }

            &.talent2 {
                right: 55%;
                top: 64%;
            }

            &.talent3 {
                right: 15%;
                top: 60%;
            }

            &.talent4 {
                right: 70%;
                top: 70%;
            }

            &.talent5 {
                right: 23%;
                top: 70%;
            }

            &.talent6 {
                right: 56%;
                top: 80%;
            }

            &.talent7 {
                right: 18%;
                top: 76%;
            }
        }
    }

    .container {
        .content {
            position: relative;
            z-index: 4;
            width: 100%;
            max-width: 445px;

            h1 {
                font-family: 'Recoleta Alt';
                font-style: normal;
                font-weight: bold;
                font-size: 2.5rem;
                line-height: 54px;
                color: #FFFFFF;
            }

            p {
                font-family: 'GT Walsheim Pro';
                font-style: normal;
                font-weight: 300;
                font-size: 1.5rem;
                line-height: 32px;
                color: #E6E6E6;
                margin-top: 16px;
            }

            button {
                background: #357DEC;
                border-radius: 10px;
                padding: 0.75rem 2.5rem;
                border: none;
                outline: none;
                font-family: GT Walsheim Pro;
                font-style: normal;
                font-weight: 500;
                font-size: 1rem;
                line-height: 32px;
                text-align: center;
                color: #FFFFFF;
                margin-top: 56px;
                position: relative;
                z-index: 1;

                &:hover {
                    background-color: #1A6BE8;
                    border-radius: 10px;

                    &:after {
                      transform: scaleX(1);
                      transform-origin: left;
                    }
                }

                &:after {
                  content: '';
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  background: rgba(0,0,0,0.2);
                  border-radius: 10px;
                  z-index: -1;
                  transition: transform 200ms ease-in;
                  transform: scaleX(0);
                  transform-origin: right;
              }
            }

            @media (max-width: 768px) {
                h1 {
                    font-size: 2rem;
                }

                p {
                    font-size: 1rem;
                }

                button {
                    padding: 0.5rem 1.5rem;
                    margin-top: 32px;
                }
            }
        }
    }
`;
